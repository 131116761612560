import React, { useState, useEffect, useCallback } from 'react';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useConfig from 'hooks/useConfig';
import debounce from 'lodash.debounce';
import { parseISO, isValid, format } from 'date-fns';
import { tr, enUS } from 'date-fns/locale';
import { Typography } from '@mui/material';

const localesMap = {
  tr: tr,
  en: enUS
};

const DateSelect = ({ value, onChange, label, disabled = false, useDateTimePicker = false, ...props }) => {
  const { i18n } = useConfig();
  const [localValue, setLocalValue] = useState(typeof value === 'string' ? parseISO(value) : value);

  const dateFormat = i18n === 'tr' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
  const dateTimeFormat = i18n === 'tr' ? 'dd.MM.yyyy HH:mm' : 'MM/dd/yyyy HH:mm';
  const timezone = i18n === 'tr' ? 'EUROPE/ISTANBUL' : 'UTC';

  const handleDateChange = (newValue) => {
    setLocalValue(newValue);
    debounceOnChange(newValue);
  };

  const debounceOnChange = useCallback(
    debounce((newDate) => {
      if (newDate && isValid(newDate)) {
        onChange(newDate);
      } else if (newDate === null) {
        onChange(null);
      }
    }, 500),
    [onChange]
  );

  useEffect(() => {
    if (typeof value === 'string') {
      setLocalValue(parseISO(value));
    } else {
      setLocalValue(value);
    }
  }, [value]);

  return !disabled ? (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localesMap[i18n]}>
      {useDateTimePicker ? (
        <DateTimePicker
          {...props}
          format={dateTimeFormat}
          label={label}
          timezone={timezone}
          value={localValue}
          onChange={handleDateChange}
          localeText={{
            cancelButtonLabel: i18n === 'tr' ? 'İptal' : 'Cancel',
            okButtonLabel: i18n === 'tr' ? 'Tamam' : 'Ok'
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              'data-cy': `datetime-select-${props?.id}` // For testing
            }
          }}
        />
      ) : (
        <DatePicker
          {...props}
          format={dateFormat}
          label={label}
          value={localValue}
          timezone={timezone}
          onChange={handleDateChange}
          localeText={{
            cancelButtonLabel: i18n === 'tr' ? 'İptal' : 'Cancel',
            okButtonLabel: i18n === 'tr' ? 'Tamam' : 'Ok'
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              'data-cy': `date-select-${props?.id}` // For testing
            }
          }}
        />
      )}
    </LocalizationProvider>
  ) : (
    <Typography>{isFinite(value) ? format(value, 'dd MMMM yyyy') : ''}</Typography>
  );
};

export default DateSelect;
