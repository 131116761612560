// project-imports
import accounting from './accounting';
/* import support from './support'; */
import applications from './applications';
import project from './project';
import stockManagement from './stock-management';
import sos from './sos';
import administration from './administration';
import developer from './developer';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [applications, project, stockManagement, accounting, sos, administration, developer /* support */]
};

export default menuItems;
