import { useEffect, useLayoutEffect, useState /*  useMemo */ } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Stack, Typography, useMediaQuery } from '@mui/material';

// project-imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { MenuOrientation } from 'config';
import { useMenuAccess } from 'hooks/access/useAccessData';
import useUnreadNotificationCount from 'hooks/sessionStorage/useUnreadNotificationCount';
import TimeagoCell from 'components/data/TimeagoCell';
import CustomTooltip from 'components/@extended/Tooltip';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
const addPermissionsToItems = (items, menuAccess) => {
  if (!Array.isArray(items)) {
    return [];
  }

  const addPermissions = (item) => {
    const access = menuAccess?.find((accessItem) => accessItem.key === item.id);

    if (access) {
      item.perms = access.permissions;
      /* console.log('Match! Permissions loading from ', JSON.stringify(access) + ' for item ' + item.id); */
    }
    if (item.children && Array.isArray(item.children)) {
      item.children = item.children.map(addPermissions);
    }
    return item;
  };

  return items.map(addPermissions);
};

const addNotificationChips = (menuItems, notificationCountByMenu) => {
  if (!Array.isArray(menuItems)) {
    return [];
  }

  const addChips = (items) => {
    return items.map((item) => {
      if (item.children && Array.isArray(item.children)) {
        item.children = addChips(item.children);
      }

      console.log('item', item);

      if (notificationCountByMenu[item.id] > 0) {
        return { ...item, chip: { size: 'small', color: 'error', label: notificationCountByMenu[item.id] } };
      } else {
        return { ...item, chip: item?.chip?.variant == 'outlined' ? item.chip : null };
      }
    });
  };
  return addChips(menuItems);
};

const filterMenuItemsByPermissions = (menuItems, userPermissions) => {
  if (!Array.isArray(menuItems)) {
    return [];
  }

  const hasPermission = (perms) => {
    return !perms || perms.some((perm) => userPermissions.includes(perm));
  };

  const filterItems = (items) => {
    return items
      .map((item) => {
        if (item.children && Array.isArray(item.children)) {
          item.children = filterItems(item.children);
        }

        if (item.type === 'collapse' || item.type === 'group') {
          if (!item.children || item.children.length === 0) {
            return null;
          }
        }

        return hasPermission(item.perms) || (item.children && item.children.length > 0) ? item : null;
      })
      .filter((item) => item !== null);
  };

  return filterItems(menuItems);
};

const Navigation = () => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { data: menuAccessData, isLoading } = useMenuAccess();
  const menuAccess = menuAccessData?.data || [];
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [_menuItem, _setMenuItem] = useState({ items: [] });
  const [menuItems, setMenuItems] = useState({ items: [] });

  //get current logged in user from jwtContext
  const { user } = useAuth();
  const roleId = user?.role_id;
  const perms = user?.permissions || [];

  const { totalCount: unreadCount, countByMenu } = useUnreadNotificationCount();
  useEffect(() => {
    if (unreadCount > 0) {
      document.title = `(${unreadCount}) Fırat Life`;
    } else {
      document.title = 'Fırat Life';
    }
  }, [unreadCount]);

  useLayoutEffect(() => {
    _setMenuItem(menuItem);
  }, [menuItem]);

  useEffect(() => {
    console.log('Menu Items Use Effect');
    let items = addPermissionsToItems(_menuItem.items, menuAccess);
    const itemsFiltered = filterMenuItemsByPermissions(items, perms);
    const filteredItemsWithNotifications = addNotificationChips(itemsFiltered, countByMenu);
    setMenuItems({ items: filteredItemsWithNotifications });
  }, [menuAccess, _menuItem, countByMenu]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon,
      perms: item.perms
    }));
  }
  if (isLoading) return null;

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <>
            <NavGroup
              key={item.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
              roleId={roleId}
              perms={perms}
            />
          </>
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  // now
  const lastUpdateTimestamp = process.env.BUILD_TIME || '2024-08-10T12:27:04.479Z';
  const displayNewChipFor = 6 * 60 * 60 * 1000; // 6 hours
  const isNew = new Date().getTime() - new Date(lastUpdateTimestamp).getTime() <= displayNewChipFor;

  // Extract the current year and month
  const now = new Date();
  const yearLastDigit = now.getFullYear().toString().slice(-1); // Get the last digit of the year
  const monthNumber = (now.getMonth() + 1).toString().padStart(2, '0'); // Get the month and pad with zero if necessary

  // Commit count (from environment variable)
  const commitCount = process.env.REACT_APP_COMMIT_COUNT || '99'; // Default to '99' if undefined

  // Version formatting
  const version = `${yearLastDigit}.${monthNumber}.${commitCount.padStart(2, '0')}`; // Example: "4.08.99"

  // sürüm
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
      <Box sx={{ flexGrow: 1, paddingY: 5 }}>
        <CustomTooltip
          arrow
          color={isNew ? 'error' : 'primary'}
          title={<TimeagoCell value={new Date(lastUpdateTimestamp).getTime()} />}
          placement="bottom"
        >
          <Stack direction={'row'} justifyContent="center" alignItems="center" sx={{ width: '100%' }} spacing={1}>
            <Typography variant="body1" color="info" align="center">
              {isNew && <Chip label="Yeni!" color="error" />}
            </Typography>
            <Typography variant="body1" color="info" align="center">
              v{version}
            </Typography>
          </Stack>
        </CustomTooltip>
      </Box>
    </Box>
  );
};

export default Navigation;
