import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2'; // Ensure this path is correct and axios is configured properly

const fetchNotifications = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();
  const { data } = await axios.get(`/api/notifications${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useNotificationsData = (params) => {
  return useQuery(['notifications', params], () => fetchNotifications(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const subscribe = async (body) => {
  return axios.post('/api/notifications/subscribe', body);
};

export const useSubscribe = () => {
  const queryClient = useQueryClient();
  return useMutation(subscribe, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    },
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const unsubscribe = async (body) => {
  return axios.post('/api/notifications/unsubscribe', body);
};

export const useUnsubscribe = () => {
  const queryClient = useQueryClient();

  return useMutation(unsubscribe, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    }
  });
};

const watch = async (body) => {
  return axios.post('/api/notifications/watch', body);
};

export const useWatch = () => {
  const queryClient = useQueryClient();
  return useMutation(
    watch,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('movement_panel');
      }
    },
    {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000
    }
  );
};

const unwatch = async (body) => {
  return axios.post('/api/notifications/unwatch', body);
};

export const useUnwatch = () => {
  const queryClient = useQueryClient();

  return useMutation(
    unwatch,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('movement_panel');
      }
    },
    {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000
    }
  );
};

const fetchWatchers = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/notifications/watchers${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useWatchers = (params) => {
  return useQuery(['watchers', params], () => fetchWatchers(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const getMyNotifications = async () => {
  return axios.get('/api/notifications/my');
};

export const useMyNotifications = () => {
  // other than that only update when myNotifications is invalidated
  return useQuery('notifications', getMyNotifications, {
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    keepPreviousData: true
  });
};

const readNotification = async (id) => {
  return axios.put(`/api/notifications/${id}/read`);
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(readNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    }
  });
};

const readAllNotifications = async () => {
  return axios.put('/api/notifications/read_all');
};

export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(readAllNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    }
  });
};
