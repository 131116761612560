import { useEffect, useState } from 'react';
import { Box, Button, FormLabel, Grid, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import { useAddCompany, useUpdateCompany, useUploadCompanyLogo } from 'hooks/company/useCompanyData';
import CompanyTypeSelect from 'components/select-boxes/CompanyTypeSelect';
import { ThemeMode } from 'config';
import Loader from 'components/Loader';
import Avatar from 'components/@extended/Avatar';
import CircularWithLabel from 'components/@extended/progress/CircularWithLabel';

// third-party
import { FormattedMessage } from 'react-intl';
import { PatternFormat } from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';

// assets
import { Camera, Copyright } from 'iconsax-react';

const validationSchema = yup.object({
  name: yup.string().required('Firma adı gereklidir'),
  code: yup
    .string()
    .required('Firma kodu gereklidir')
    .min(2, 'Firma kodu en az 2 karakter olmalıdır')
    .max(8, 'Firma kodu en fazla 8 karakter olabilir'),
  address: yup.string(),
  type: yup.string().required('Firma tipi seçimi gereklidir'),
  vat_number: yup
    .string()
    .min(10, 'Vergi numarası en az 10 karakter olmalıdır')
    .max(11, 'T.C. kimlik numarası en fazla 11 karakter olabilir')
    .test('is-num', 'Vergi numarası sadece rakam içerebilir', (value) => {
      if (value) {
        return /^\d+$/.test(value);
      }
      return true;
    }),
  tax_office: yup.string(),
  phone: yup.string().matches(/^\(\d{4}\) \d{3} \d{4}$/, 'Geçerli bir telefon numarası girin (örn: (0312) 789 9900)'),
  mail: yup.string().email('Geçerli bir e-posta adresi girin')
});

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK  ||============================== //

const CompanyCreationForm = ({ itemToUpdate, setCreationVisible, setIsChanged }) => {
  const theme = useTheme();
  const { mutate: addCompany, data: addResponse } = useAddCompany();
  const { mutate: updateCompany, data: updateResponse } = useUpdateCompany();
  const { mutate: uploadCompanyLogo, isLoading: isUploadingImage, data: uploadLogoResponse } = useUploadCompanyLogo();

  const formik = useFormik({
    initialValues: {
      name: itemToUpdate?.name || '',
      code: itemToUpdate?.code || '',
      address: itemToUpdate?.address || '',
      type: itemToUpdate?.type || 'Ana Firma',
      vat_number: itemToUpdate?.vat_number || '',
      tax_office: itemToUpdate?.tax_office || '',
      phone: itemToUpdate?.phone || '',
      mail: itemToUpdate?.mail || ''
    },
    validationSchema,
    onSubmit: () => {
      console.log('formik.values', formik.values);
      const payload = {
        name: formik.values.name,
        code: formik.values.code,
        address: formik.values.address,
        type: formik.values.type,
        vat_number: formik.values.vat_number,
        tax_office: formik.values.tax_office,
        phone: formik.values.phone,
        mail: formik.values.mail
      };
      if (itemToUpdate != undefined) {
        updateCompany({ id: itemToUpdate.id, ...payload });
      } else {
        addCompany(payload);
      }
    }
  });

  useEffect(() => {
    if (addResponse?.data?.success) {
      setIsChanged(false);
      setCreationVisible(false);
    }
  }, [addResponse?.data]);

  useEffect(() => {
    if (updateResponse?.data?.success) {
      setIsChanged(false);
      setCreationVisible(false);
    }
  }, [updateResponse?.data]);

  useEffect(() => {
    if (uploadLogoResponse?.data?.success) {
      setIsChanged(false);
      setCreationVisible(false);
    }
  }, [uploadLogoResponse?.data]);

  useEffect(() => {
    setIsChanged(formik.dirty);
  }, [formik.dirty, setIsChanged]);

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const onProgress = (percentCompleted) => {
    setProgress(percentCompleted);
  };
  useEffect(() => {
    if (selectedImage) {
      uploadCompanyLogo({ body: { image: selectedImage, company_id: itemToUpdate?.id }, onProgress });
    }
  }, [selectedImage]);

  const company_logo = process.env.REACT_APP_API_URL + itemToUpdate?.logo_url || null;

  return (
    <MainCard title={itemToUpdate != undefined ? 'Firma Bilgisi Güncelle' : 'Firma Tanımlama'}>
      {isUploadingImage && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {itemToUpdate && (
            <Grid item xs={12}>
              <Stack spacing={2.5} alignItems="center" sx={{ m: 3 }}>
                <FormLabel
                  htmlFor="change-company-logo"
                  sx={{
                    position: 'relative',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    '&:hover .MuiBox-root': { opacity: 1 },
                    cursor: 'pointer'
                  }}
                >
                  <Avatar alt="Logo" variant="rounded" src={company_logo} sx={{ width: 200, height: 200 }}>
                    <Copyright fontSize={64} />
                  </Avatar>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .55)' : 'rgba(0,0,0,.45)',
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Stack spacing={0.5} alignItems="center">
                      <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '1.5rem' }} />
                      <Typography sx={{ color: 'secondary.lighter' }} variant="h6">
                        {itemToUpdate?.logo_url != null ? 'Firma Logosu Değiştir' : 'Firma Logosu Yükle'}
                      </Typography>
                    </Stack>
                  </Box>
                  {progress > 0 && progress < 100 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.25)',
                        width: '100%',
                        height: '100%',
                        opacity: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularWithLabel size={72} variant="determinate" value={progress} color="success" />
                    </Box>
                  )}
                </FormLabel>
                <TextField
                  type="file"
                  id="change-company-logo"
                  placeholder="Outlined"
                  variant="outlined"
                  sx={{ display: 'none' }}
                  onChange={(e) => setSelectedImage(e.target.files?.[0])}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={6} lg={2}>
            <Stack spacing={1}>
              <InputLabel>Firma Tipi</InputLabel>

              <CompanyTypeSelect
                id={'type'}
                selectedCompanyType={formik.values.type}
                setSelectedCompanyType={(value) => formik.setFieldValue('type', value)}
                formik={formik}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} lg={2}>
            <Stack spacing={1}>
              <InputLabel htmlFor="name">Firma Kodu</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="code"
                name="code"
                placeholder=""
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Stack spacing={1}>
              <InputLabel htmlFor="name">Firma Adı</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                name="name"
                placeholder=""
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>Telefon</InputLabel>
              {/* <TextField
                autoComplete="off"
                fullWidth
                id="phone"
                name="phone"
                placeholder='Örn: "03125559900'
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              /> */}
              <PatternFormat
                format="(####) ### ####"
                mask="_"
                fullWidth
                customInput={TextField}
                placeholder="ör: (0312) 789 9900"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>E-posta</InputLabel>
              <TextField
                fullWidth
                placeholder="Örn: info@firatlife.com"
                id="mail"
                name="mail"
                value={formik.values.mail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mail && Boolean(formik.errors.mail)}
                helperText={formik.touched.mail && formik.errors.mail}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>Vergi NO/T.C. NO</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="vat_number"
                name="vat_number"
                value={formik.values.vat_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.vat_number && Boolean(formik.errors.vat_number)}
                helperText={formik.touched.vat_number && formik.errors.vat_number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{formik.values.vat_number.length >= 11 ? 'T.C. NO' : 'Vergi NO'}</InputAdornment>
                  )
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>Vergi Dairesi</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="tax_office"
                name="tax_office"
                value={formik.values.tax_office}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tax_office && Boolean(formik.errors.tax_office)}
                helperText={formik.touched.tax_office && formik.errors.tax_office}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel>Firma Adresi</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="address"
                name="address"
                multiline
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button variant="contained" type="submit" color="primary">
                  <FormattedMessage id={itemToUpdate != undefined ? 'update' : 'add'} />
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default CompanyCreationForm;
