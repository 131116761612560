import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AvatarGroup,
  Box,
  Button,
  Drawer,
  Grid,
  Typography,
  Stack,
  /* Divider, */
  Tooltip,
  Badge,
  Dialog,
  List,
  ListItemText,
  ListItem,
  Switch,
  Portal
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';

// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import { useMovementPanelData } from 'hooks/movementPanel/useMovementPanelData';
import { useAddApprove } from 'hooks/approve/useApprovesData';
import Avatar from './@extended/Avatar';
import LoadingCard from './LoadingCard';
import Dot from './@extended/Dot';
import CommentCard from './CommentCard';
import UpdateLogCard from './UpdateLogCard';
import AddComment from './AddComment';
import AlertApprove from './alerts/AlertApprove';
import ApproveCell from './data/ApproveCell';
import TimeagoCell from './data/TimeagoCell';
import { useWatch, useUnwatch } from 'hooks/notification/useNotificationsData';
import DocumentCard from 'sections/apps/document/DocumentCard';
import MovementPanelSkeleton from './cards/skeleton/MovementPanelSkeleton';

// assets
import {
  Add,
  Hierarchy,
  Discover,
  ShieldTick,
  UserTag,
  Messages3,
  Eye,
  ShieldCross,
  Refresh,
  Maximize4,
  Notification,
  Folder2,
  DocumentUpload
} from 'iconsax-react';
import AddDocument from 'sections/apps/document/AddDocument';
import { PopupTransition } from './@extended/Transitions';
import { tableNames } from 'data/tableNames';
import CustomTooltip from './@extended/Tooltip';
import TimestampCell from './data/TimestampCell';
import AlertClosing from './alerts/AlertClosing';
import usePreventNavigation from 'hooks/usePreventNavigation';
import useAuth from 'hooks/useAuth';
import MainCard from './MainCard';

// ==============================|| KANBAN BACKLOGS - ADD ITEM ||============================== //

const MovementPanel = ({ variant = 'drawer', open, handleDrawerOpen, table_name, object_id, nano_id }) => {
  const theme = useTheme();
  const { isLoggedIn } = useAuth();

  const { data, isLoading, isFetching, refetch } = useMovementPanelData(table_name, object_id, open, nano_id);
  const { mutate: watch, isLoading: watchLoading } = useWatch();
  const { mutate: unwatch, isLoading: unwatchLoading } = useUnwatch();

  const [alertOpen, setAlertOpen] = useState(false);
  const [isApprove, setIsApprove] = useState();
  const [docCloseAlertVisible, setDocCloseAlertVisible] = useState(false);
  const [isDocChanged, setIsDocChanged] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const handleApproveButton = (is_approve) => {
    setIsApprove(is_approve);
    setAlertOpen(true);
  };

  usePreventNavigation(isDocChanged);

  const created_by = data?.data?.data?.created_by;
  const approves = data?.data?.data?.approves || [];
  const seenInfos = data?.data?.data?.seen_infos || [];
  const watchers = data?.data?.data?.watchers || [];
  /*   const table_watchers = data?.data?.data?.table_watchers || []; */
  const created_at = data?.data?.data?.created_at || '';
  const comments = data?.data?.data?.comments || [];
  const update_logs = data?.data?.data?.update_logs || [];
  const documents = data?.data?.data?.documents || [];
  const isWatchingObject = data?.data?.data?.isWatchingObject || false;
  const isWatchingTable = data?.data?.data?.isWatchingTable || false;
  const payload = { table_name, object_id };
  const [showAllAvatars, setShowAllAvatars] = useState(false);

  const { mutate: addApprove, data: approveResponse } = useAddApprove();

  /*  console.log('table_watchers', table_watchers); */

  const handleAddApprove = (is_approve) => {
    console.log('is_approve', is_approve);
    addApprove({ table_name, object_id, is_approve });
  };

  const ICON_SIZE = 32;

  const [docAddVisible, setDocAddVisible] = useState(false);

  useEffect(() => {
    if (approveResponse?.data?.success === true) {
      setAlertOpen(false);
    }
  }, [approveResponse]);

  if (!open) return null;

  const content = (
    <SimpleBar
      sx={{
        overflowX: 'hidden',
        height: variant == 'drawer' ? '100vh' : 'auto'
      }}
    >
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          if (isDocChanged) setDocCloseAlertVisible(true);
          else setDocAddVisible(false);
        }}
        open={docAddVisible}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <AddDocument
          setIsChanged={setIsDocChanged}
          onCancel={() => {
            setDocAddVisible(false);
          }}
          table_name={table_name}
          object_id={object_id}
          nano_id={nano_id}
        />
      </Dialog>
      <AlertClosing
        open={docCloseAlertVisible}
        onAgree={() => {
          setDocAddVisible(false);
          setIsDocChanged(false);
          setDocCloseAlertVisible(false);
        }}
        onClose={() => setDocCloseAlertVisible(false)}
        highlight="Doküman Formu"
      />

      <Box sx={{ p: 3, pb: 1, pt: 1, bgcolor: theme.palette.secondary[200] }}>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Discover size={32} variant="Bulk" />
            {/*  <Typography variant="h4">Hareketler</Typography> */}
            <Typography variant="h4">
              {variant == 'inline' ? 'Hareketler ' : ''}
              {tableNames[table_name]} #{object_id}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Tooltip title="Yenile">
              <IconButton color="primary" onClick={() => refetch()} size="small" sx={{ fontSize: '0.875rem' }}>
                <Refresh />
              </IconButton>
            </Tooltip>

            {isLoggedIn && (
              <CustomTooltip
                arrow
                color="primary"
                placement="left"
                title={
                  <Stack spacing={2}>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={'Yakın Takip'}
                          secondaryTypographyProps={{ color: 'secondaryText' }}
                          secondary={`${tableNames[table_name]} #${object_id} ile ilgili bütün hareketlerden haberdar ol`}
                        />

                        <Switch
                          disabled={isFetching || isLoading || watchLoading || unwatchLoading}
                          color="warning"
                          checked={isWatchingObject}
                          defaultChecked
                          onChange={() => (isWatchingObject ? unwatch(payload) : watch(payload))}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={'Tablo Takibi'}
                          secondaryTypographyProps={{ color: 'secondaryText' }}
                          secondary={`Bu tablo ile ilgili bütün ekleme ve silme işlemlerinden haberdar ol`}
                        />

                        <Switch
                          disabled={isFetching || isLoading || watchLoading || unwatchLoading}
                          color="warning"
                          checked={isWatchingTable}
                          defaultChecked
                          onChange={() => (isWatchingTable ? unwatch({ table_name }) : watch({ table_name }))}
                        />
                      </ListItem>
                    </List>
                  </Stack>
                }
              >
                <IconButton
                  color="warning"
                  onClick={() => (isWatchingObject ? unwatch(payload) : watch(payload))}
                  size="small"
                  sx={{ fontSize: '0.875rem' }}
                >
                  <Notification variant="Bold" />
                </IconButton>
              </CustomTooltip>
            )}

            {variant == 'drawer' && (
              <Tooltip title={fullScreen ? 'Küçült' : 'Büyüt'}>
                <IconButton
                  color="info"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                  }}
                  size="small"
                  sx={{ fontSize: '0.875rem' }}
                >
                  <Maximize4 />
                </IconButton>
              </Tooltip>
            )}

            {variant == 'drawer' && (
              <Tooltip title="Kapat">
                <IconButton color="error" onClick={handleDrawerOpen} size="medium" sx={{ fontSize: '0.875rem' }}>
                  <Add style={{ transform: 'rotate(49deg)' }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Box>
      {/* <Divider /> */}

      {isLoading ? (
        <LoadingCard open={true} />
      ) : (
        <>
          <Box sx={{ p: 1, pl: 3, rowGap: 1 }}>
            <Stack direction="row" alignItems="center" columnGap={4}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <UserTag size={ICON_SIZE} scale={2} variant="Bold" color={theme.palette.secondary.main} />
                <Typography variant="h5">Oluşturan</Typography>
              </Stack>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar size="md" src={process.env.REACT_APP_API_URL + created_by?.avatar_url} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                    <Grid item>
                      <Typography align="left" variant="subtitle1" component="div">
                        {created_by?.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Dot size={6} sx={{ mt: -0.25 }} color="secondary" />
                        <TimestampCell value={created_at} detailed={true} />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Box sx={{ p: 1, pl: 3 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Notification size={ICON_SIZE} variant="Bold" color={theme.palette.warning.main} />
              <Tooltip
                open={showAllAvatars}
                placement="top-end"
                title={watchers.map((watcher) => (
                  <Stack
                    key={watcher.timestamp + watcher.user.id}
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    spacing={2}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar size="xs" src={process.env.REACT_APP_API_URL + watcher.user?.avatar_url} />
                      <Typography>{watcher.user?.name}</Typography>
                    </Stack>
                    <Typography variant="body2">
                      <TimeagoCell value={watcher.timestamp} />
                    </Typography>
                  </Stack>
                ))}
              >
                <Typography variant="h5">Yakın Takipçiler</Typography>
              </Tooltip>
              <AvatarGroup
                max={12}
                spacing="medium"
                sx={{ '& .MuiAvatarGroup-avatar': { cursor: 'pointer' } }}
                componentsProps={{
                  additionalAvatar: {
                    onMouseLeave: () => {
                      setShowAllAvatars(false);
                    },
                    onClick: () => {
                      setShowAllAvatars(true);
                    }
                  }
                }}
              >
                {watchers.map((watcher) => (
                  <Tooltip
                    key={watcher.timestamp + watcher.user?.id}
                    title={
                      <Stack>
                        <TimeagoCell value={watcher.timestamp} />
                        <Typography variant="caption">{watcher.user?.name}</Typography>
                      </Stack>
                    }
                    placement="top"
                  >
                    <Stack>
                      <Badge
                        badgeContent={<Typography>🔔</Typography>}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      >
                        <Avatar size="md" src={`${process.env.REACT_APP_API_URL}${watcher.user?.avatar_url}`} />
                      </Badge>
                    </Stack>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Stack>
          </Box>

          <Box sx={{ p: 1, pl: 3 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Eye size={ICON_SIZE} variant="Bulk" color={theme.palette.info.main} />
              <Tooltip
                open={showAllAvatars}
                placement="top-end"
                title={seenInfos.map((seenInfo) => (
                  <Stack
                    key={seenInfo.timestamp + seenInfo.user.id}
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    spacing={2}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar size="xs" src={process.env.REACT_APP_API_URL + seenInfo.user?.avatar_url} />
                      <Typography>{seenInfo.user?.name}</Typography>
                    </Stack>
                    <Typography variant="body2">
                      <TimeagoCell value={seenInfo.timestamp} />
                    </Typography>
                  </Stack>
                ))}
              >
                <Typography variant="h5">Görüldü Bilgisi</Typography>
              </Tooltip>
              <AvatarGroup
                max={12}
                spacing="medium"
                sx={{ '& .MuiAvatarGroup-avatar': { cursor: 'pointer' } }}
                componentsProps={{
                  additionalAvatar: {
                    onMouseLeave: () => {
                      setShowAllAvatars(false);
                    },
                    onClick: () => {
                      setShowAllAvatars(true);
                    }
                  }
                }}
              >
                {seenInfos.map((seenInfo) => (
                  <Tooltip
                    key={seenInfo.timestamp + seenInfo.user?.id}
                    title={
                      <Stack>
                        <TimeagoCell value={seenInfo.timestamp} />
                        <Typography variant="caption">{seenInfo.user?.name}</Typography>
                      </Stack>
                    }
                    placement="top"
                  >
                    <Stack>
                      <Badge
                        badgeContent={<Typography>✅</Typography>}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      >
                        <Avatar size="md" src={`${process.env.REACT_APP_API_URL}${seenInfo.user?.avatar_url}`} />
                      </Badge>
                    </Stack>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Stack>
          </Box>

          <Box
            sx={{
              '& .MuiAccordion-root': {
                borderColor: 'transparent',
                '& .MuiAccordionSummary-root': {
                  bgcolor: 'transparent',
                  flexDirection: 'row'
                },
                '& .MuiAccordionDetails-root': {
                  borderColor: 'transparent'
                },
                '& .Mui-expanded': {
                  color: theme.palette.success.main
                }
              }
            }}
          >
            <Accordion>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ShieldTick size={ICON_SIZE} variant="Outline" color={theme.palette.success.main} />
                  <Typography variant="h5">Onay Akışı</Typography>
                  <ApproveCell data-cy={`mp-approve-cell`} value={approves.length > 0 ? approves[approves.length - 1].level : 0} />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Timeline
                    position="right"
                    sx={{
                      '& .MuiTimelineItem-root': { minHeight: 80 },
                      '& .MuiTimelineOppositeContent-root': { mt: 0.5 },
                      '& .MuiTimelineDot-root': {
                        borderRadius: 1.25,
                        boxShadow: 'none',
                        margin: 0,
                        ml: 1.25,
                        mr: 1.25,
                        p: 1,
                        '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
                      },
                      '& .MuiTimelineContent-root': { borderRadius: 1, bgcolor: 'secondary.lighter', height: '100%' },
                      '& .MuiTimelineConnector-root': {
                        border: '1px dashed',
                        borderColor: 'secondary.light',
                        bgcolor: 'transparent'
                      }
                    }}
                  >
                    {approves.map((approve) => (
                      <TimelineItem key={approve.id}>
                        <TimelineOppositeContent align="left" variant="body2" color="text.secondary">
                          <TimeagoCell value={approve.timestamp} />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot sx={{ color: 'success.main', bgcolor: 'success.lighter' }}>
                            <Avatar size="md" src={`${process.env.REACT_APP_API_URL}${approve.user?.avatar_url}`} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="h6" noWrap component="span">
                            {approve.user?.name}
                          </Typography>
                          {approve.is_approve == 1 ? (
                            <Typography noWrap color="textSecondary">
                              {approve.level}. kademe onay verdi ✅
                            </Typography>
                          ) : (
                            <Typography noWrap color="textSecondary">
                              {approve.level}. kademeye düşürdü ❌
                            </Typography>
                          )}
                          <Typography noWrap color="textSecondary"></Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Stack>
              </AccordionDetails>
            </Accordion>

            {isLoggedIn && (
              <Stack direction="row" alignItems="center" justifyContent={'flex-end'} columnGap={2} marginRight={1} marginBottom={2}>
                <AlertApprove
                  highlight={`${tableNames[table_name]} #${object_id}  için`}
                  open={alertOpen}
                  setOpen={setAlertOpen}
                  is_approve={isApprove}
                  handleApprove={handleAddApprove}
                />
                <Button
                  data-cy="disapprove-button"
                  disabled={approves?.length == 0}
                  color="error"
                  variant="text"
                  onClick={() => {
                    handleApproveButton(0);
                  }}
                  size="large"
                  sx={{ fontSize: '0.875rem' }}
                >
                  <ShieldCross />
                  Onay Kaldır
                </Button>
                <Button
                  data-cy="approve-button"
                  color="success"
                  variant="text"
                  onClick={() => {
                    handleApproveButton(1);
                  }}
                  size="large"
                  sx={{ fontSize: '0.875rem' }}
                >
                  <ShieldTick />
                  Onay Ver
                </Button>
              </Stack>
            )}
          </Box>

          <Box
            sx={{
              '& .MuiAccordion-root': {
                borderColor: 'transparent',
                '& .MuiAccordionSummary-root': {
                  bgcolor: 'transparent',
                  flexDirection: 'row'
                },
                '& .MuiAccordionDetails-root': {
                  borderColor: 'transparent'
                },
                '& .Mui-expanded': {
                  color: theme.palette.primary.main
                }
              }
            }}
          >
            <Accordion>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Hierarchy size={ICON_SIZE} variant="Bulk" color={theme.palette.primary.main} />
                  <Typography variant="h5">Değişiklikler {update_logs.length}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" spacing={1}>
                  {update_logs.map((update_log, index) => (
                    <UpdateLogCard key={index} updateLog={update_log} />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            sx={{
              '& .MuiAccordion-root': {
                borderColor: 'transparent',
                '& .MuiAccordionSummary-root': {
                  bgcolor: 'transparent',
                  flexDirection: 'row'
                },
                '& .MuiAccordionDetails-root': {
                  borderColor: 'transparent'
                },
                '& .Mui-expanded': {
                  color: theme.palette.warning.light
                }
              }
            }}
          >
            <Accordion>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Folder2 size={ICON_SIZE} variant="Bulk" color={theme.palette.warning.light} />
                  <Typography variant="h5">Dokümanlar {documents.length}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  <Grid container spacing={1}>
                    {documents.map((doc) => (
                      <Grid
                        key={doc.id}
                        item
                        xs={fullScreen || variant == 'inline' ? 6 : 12}
                        xl={fullScreen || variant == 'inline' ? 4 : 12}
                      >
                        <DocumentCard key={doc.id} doc={doc} />
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    onClick={() => {
                      setDocAddVisible(true);
                    }}
                    style={{ alignSelf: 'flex-end' }}
                    variant="contained"
                    startIcon={<DocumentUpload />}
                    color="primary"
                  >
                    <FormattedMessage id="upload-document" />
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ p: 1, pl: 3 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Messages3 size={ICON_SIZE} variant="Bulk" color={theme.palette.secondary.main} />
              <Typography variant="h5">Yorumlar {comments.length}</Typography>
            </Stack>

            {comments.map((comment, index) => (
              <CommentCard key={index} comment={comment} />
            ))}
            <Stack marginTop={1}>
              <AddComment table_name={table_name} object_id={object_id} nano_id={nano_id} />
            </Stack>
          </Box>
        </>
      )}
    </SimpleBar>
  );

  if (variant === 'inline') {
    if (isLoading) return <MovementPanelSkeleton />;
    return (
      <MainCard content={false} sx={{ width: '100%' }}>
        {content}
      </MainCard>
    );
  }

  return (
    <Portal>
      <Drawer
        sx={{
          ml: open ? 3 : 0,
          flexShrink: 0,
          zIndex: 1300,
          overflowX: 'hidden',
          width: fullScreen ? { md: '100%' } : { xs: 400, md: 750 },
          '& .MuiDrawer-paper': {
            width: fullScreen ? { md: '100%' } : { xs: 400, md: 750 },
            border: 'none',
            borderRadius: '0px'
          }
        }}
        onClick={(event) => {
          // disable click event
          event.stopPropagation();
        }}
        variant="temporary"
        anchor="right"
        open={open}
        ModalProps={{ keepMounted: true }}
        onClose={handleDrawerOpen}
      >
        {open && (isLoading ? <MovementPanelSkeleton /> : content)}
      </Drawer>
    </Portal>
  );
};

MovementPanel.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  table_name: PropTypes.string.isRequired,
  object_id: PropTypes.string.isRequired,
  nano_id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['drawer', 'inline']) // New prop
};

export default MovementPanel;
