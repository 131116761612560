import { useState, useMemo, useEffect } from 'react';
import React from 'react';

// material-ui
import { Grid, Stack } from '@mui/material';

// project-imports
import FilterResultsDisplay from 'components/displays/FilterResultsDisplay';
import { TablePagination } from 'components/third-party/ReactTable';
import LoadingModal from 'components/LoadingModal';
import QuotationTable from 'sections/tables/react-table/QuotationTable';
import useCompanyQuotations from 'hooks/company/quotations/useCompanyQuotationsData';

// third-party
import { format } from 'date-fns';

const CompanyQuotations = ({ company }) => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState({ id: 'created_at', desc: true });
  const [search, setSearch] = useState('');

  const params = {
    startDate: filters?.startDate
      ? format(
          new Date(new Date(filters.startDate).getTime() + new Date(filters.startDate).getTimezoneOffset() * 60000),
          'yyyy-MM-dd HH:mm:ss'
        )
      : null,
    endDate: filters?.endDate
      ? format(new Date(new Date(filters.endDate).getTime() + new Date(filters.endDate).getTimezoneOffset() * 60000), 'yyyy-MM-dd HH:mm:ss')
      : null,
    supplier_id: filters?.subcontractor?.id || company?.id,
    demanding_id: filters?.contractor?.id,

    page_size: pageSize,
    page,
    sort: sortBy?.id,
    desc: sortBy?.desc,
    search: search && search.length > 0 ? search : undefined
  };

  // delete null or undefined values from params
  Object.keys(params).forEach((key) => params[key] == null && delete params[key]);

  const { isLoading, isFetching, data: quotationsData } = useCompanyQuotations(params);
  const quotations = useMemo(() => quotationsData?.data || [], [quotationsData]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  return (
    <Grid container spacing={3}>
      <LoadingModal open={isLoading} />
      <Grid item xs={12} md={12} lg={12}>
        <Stack spacing={2} alignSelf={'center'}>
          <FilterResultsDisplay
            total={quotationsData?.total}
            filteredTotal={quotationsData?.filteredTotal}
            executionTime={quotationsData?.executionTime}
            isFetching={isFetching}
          />
        </Stack>
        <QuotationTable
          company={company}
          data={quotations}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSearch={setSearch}
          search={search}
          filters={filters}
          setFilters={setFilters}
          Pagination={() => (
            <TablePagination
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              pageCount={quotationsData?.page_count}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyQuotations;
