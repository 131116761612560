import PropTypes from 'prop-types';
import { createContext, useState, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router';
// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
/* import Loader from 'components/Loader'; */
import axios from 'utils/axios2';
import { useLogin } from 'hooks/auth/useAuthData';

// Using FingerprintJS to generate a visitor identifier
import FingerprintJS from '@fingerprintjs/fingerprintjs';

async function getDeviceFingerprint() {
  const fp = await FingerprintJS.load();

  const result = await fp.get();
  /* console.log('fingerprintJS result', result); */
  return result.visitorId; // This is the device fingerprint
}

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isSupplierLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const decodedToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  return decoded;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const { mutateAsync } = useLogin();
  const location = useLocation();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [token, setToken] = useState(null);
  useEffect(() => {
    const init = async () => {
      try {
        console.log('JWT CONTEXT location.pathname', location.pathname);

        const serviceToken = localStorage.getItem('serviceToken');

        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          /* const response = await axios.get('/api/users/myself');
          console.log(' 123 response', response?.data?.data);
          const user = response?.data?.data; */
          const user = decodedToken(serviceToken);
          console.log('user', user);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              isSupplierLoggedIn: user.is_supplier === '1',
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);

        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);
  console.log('token is:', token);

  const login = async (credentials) => {
    try {
      console.log('credentials', credentials);
      const deviceFingerprint = await getDeviceFingerprint();
      credentials = {
        ...credentials,
        deviceFingerprint
      };

      mutateAsync(credentials)
        .then((data) => {
          console.log('data', data);

          const response = data;

          const { success, serviceToken, user } = response.data;
          console.log('response.data', response.data);
          if (success) {
            setSession(serviceToken);
            // get token from cookies
            const token = document.cookie
              ?.split('; ')
              ?.find((row) => row.startsWith('token='))
              ?.split('=')[1];
            setToken(token);
            verifyToken(token);

            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                isSupplierLoggedIn: user.is_supplier === '1',
                user
              }
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } catch (err) {
      console.error('Login Error', err);
      dispatch({
        type: LOGOUT
      });
      return err;
    }
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    axios.post('/api/auth/logout');
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return /*  <Loader />; */ null;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
