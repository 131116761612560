import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, CircularProgress, Drawer, FormControlLabel, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import { ThemeMode } from 'config';
import { useMyNotifications, useReadAllNotifications, useSubscribe, useUnsubscribe } from 'hooks/notification/useNotificationsData';
import NotificationCard from 'components/cards/item/NotificationCard';
import NotificationCardSkeleton from 'components/cards/skeleton/NotificationCardSkeleton';
import { askForPermission, checkNotificationPermission, checkSubscription, unsubscribeUser } from 'utils/notificationService';

// assets
import { Add, Notification as NotificationIcon, Setting2 } from 'iconsax-react';

// third-party
import { useQueryClient } from 'react-query';
import useRecentRoutes from 'hooks/useRecentRoutes';

// ==============================|| HEADER CONTENT - MyNotifications ||============================== //

const MyNotifications = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mutate: subscribe, isLoading: subscribing } = useSubscribe();
  const { mutate: unsubscribe, isLoading: unsubscribing } = useUnsubscribe();
  const { mutate: readAllNotifications } = useReadAllNotifications();
  const { data: myNotificationsData, isLoading, isFetching, refetch } = useMyNotifications();
  const myNotifications = myNotificationsData?.data?.data || [1, 2, 3, 4, 5];
  const unreadCount = myNotificationsData?.data?.unreadCount || 0;
  const unreadCountsByMenu = myNotificationsData?.data?.unreadCountsByMenu || {};
  const userEndpoints = myNotificationsData?.data?.endpoints || [];
  const [isSubscribed, setIsSubscribed] = useState(true);

  useEffect(() => {
    sessionStorage.setItem('unreadNotificationCount', unreadCount);
    sessionStorage.setItem('unreadCountsByMenu', JSON.stringify(unreadCountsByMenu));
    window.dispatchEvent(new Event('storage'));
  }, [unreadCount]);

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    checkNotificationPermission(queryClient);
    checkSubscription(userEndpoints, setIsSubscribed);
    if (!open) refetch();
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    checkNotificationPermission(queryClient);
    checkSubscription(userEndpoints, setIsSubscribed);
  }, [userEndpoints]);

  const handleSwitchPushNotifications = (event) => {
    if (event.target.checked) {
      askForPermission(subscribe);
    } else {
      unsubscribeUser(unsubscribe, setIsSubscribed, queryClient);
    }
  };

  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    setIsWorking(subscribing || unsubscribing);
  }, [subscribe]);

  useEffect(() => {
    setIsWorking(subscribing || unsubscribing);
  }, [setIsSubscribed]);

  const recentRoutes = useRecentRoutes(5);
  useEffect(() => {
    if (!isLoading && !isWorking && !isSubscribed) {
      const index = recentRoutes?.findIndex((route) => route[0] == '/give-permission-to-notifications');
      console.log('index', index);

      if (recentRoutes?.length != 0 && index === -1) {
        setTimeout(() => {
          navigate('/give-permission-to-notifications');
        }, 2000);
      }
    }
  }, [isSubscribed, recentRoutes, isWorking, navigate]);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'secondary.100';

  const badgeCountent = isSubscribed ? (unreadCount > 99 ? '99+' : unreadCount) : 'izin ver';
  return (
    <Tooltip title={'Bildirimlerim'}>
      <>
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
          <IconButton
            className="bildirimlerButonu"
            color="secondary"
            variant="light"
            onClick={handleToggle}
            aria-label="settings toggler"
            size="large"
            sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
          >
            <Badge badgeContent={badgeCountent} color="error" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
              <NotificationIcon variant="Bold" />
            </Badge>
          </IconButton>
        </Box>

        <Drawer
          sx={{ zIndex: 2001 }}
          anchor="right"
          onClose={handleToggle}
          open={open}
          PaperProps={{ sx: { width: { xs: 350, sm: 500 } } }}
        >
          {open && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: 'background.default'
                }}
              >
                {/* Top Part - Sticky */}
                <Box
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 2001,
                    backgroundColor: 'background.default'
                  }}
                >
                  <MainCard content={false} sx={{ border: 'none', borderRadius: 0, backgroundColor: 'background.default' }}>
                    <Box sx={{ p: 2.5, display: 'flex', flexDirection: 'column' }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1.5}>
                          <Badge badgeContent={unreadCount} color="error" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
                            <NotificationIcon variant="Bold" />
                          </Badge>
                          <Typography variant="h4">Bildirimler</Typography>

                          <Button
                            onClick={() => {
                              readAllNotifications();
                            }}
                            variant="text"
                            color="primary"
                          >
                            Hepsini Okundu Yap
                          </Button>
                        </Stack>
                        <IconButton color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
                          <Add size={28} style={{ transform: 'rotate(45deg)' }} />
                        </IconButton>
                      </Stack>
                    </Box>
                  </MainCard>
                </Box>

                {/* Scrollable Notification Cards */}
                <SimpleBar
                  sx={{
                    '& .simplebar-content': {
                      flexGrow: 1,
                      overflowY: 'auto',
                      backgroundColor: 'background.default'
                    },
                    p: 1.5
                  }}
                >
                  <Grid container spacing={1.5}>
                    {isLoading ||
                      (isFetching && (
                        <Grid item xs={12}>
                          <Stack direction="row" alignItems="center" justifyContent="center">
                            <CircularProgress color="secondary" size={32} thickness={4} />
                          </Stack>
                        </Grid>
                      ))}
                    {myNotifications.map((notification) => (
                      <Grid key={notification.id} item xs={12}>
                        {isLoading ? <NotificationCardSkeleton /> : <NotificationCard notification={notification} />}
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mt: 2 }}>
                        <Button
                          onClick={() => {
                            navigate('/notifications');
                            handleToggle();
                          }}
                          variant="text"
                          color="primary"
                        >
                          Tümünü Gör
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </SimpleBar>

                {/* Bottom Part - Sticky */}
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2001,
                    backgroundColor: 'background.default'
                  }}
                >
                  <MainCard content={false} sx={{ border: 'none', borderRadius: 0, backgroundColor: 'background.default' }}>
                    <Box sx={{ p: 2 }}>
                      <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" spacing={1.5}>
                          <Setting2 variant="Bold" />
                          <Typography variant="h4">Ayarlar</Typography>
                        </Stack>
                        <FormControlLabel
                          disabled={isWorking || subscribing || unsubscribing}
                          control={
                            <Switch
                              id={'notifications-permission-switch'}
                              checked={isSubscribed}
                              onChange={handleSwitchPushNotifications}
                            />
                          }
                          labelPlacement="start"
                          label="Bildirimleri Aç/Kapat"
                        />
                      </Stack>
                    </Box>
                  </MainCard>
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      </>
    </Tooltip>
  );
};

export default MyNotifications;
