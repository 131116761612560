import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from 'store';

const initialState = {
  calendarView: 'dayGridMonth',
  error: false,
  events: [],
  isLoader: false,
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

// ==============================|| SLICE - CALENDAR ||============================== //

const calendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // loader
    loading(state) {
      state.isLoader = true;
    },

    // error
    hasError(state, action) {
      state.isLoader = false;
      state.error = action.payload;
    },

    // event list
    setEvents(state, action) {
      state.isLoader = false;
      state.events = action.payload;
    },

    // update calendar view
    updateCalendarView(state, action) {
      state.calendarView = action.payload;
    },

    // select event
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },

    // create event
    createEvent(state, action) {
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = action.payload;
    },

    // update event
    updateEvent(state, action) {
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = action.payload;
    },

    // delete event
    deleteEvent(state, action) {
      const { eventId } = action.payload;
      state.isModalOpen = false;
      const deleteEvent = state.events.filter((user) => user.id !== eventId);
      state.events = deleteEvent;
    },

    // select date range
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isModalOpen = true;
      state.selectedRange = { start, end };
    },

    // modal toggle
    toggleModal(state) {
      state.isModalOpen = !state.isModalOpen;
      if (state.isModalOpen === false) {
        state.selectedEventId = null;
        state.selectedRange = null;
      }
    }
  }
});

export default calendar.reducer;

export const { selectEvent, toggleModal, updateCalendarView } = calendar.actions;

export function getEvents() {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      dispatch(
        calendar.actions.setEvents([
          {
            id: '5e8882f1f0c9216397e05a9b',
            allDay: false,
            color: '#f5222d',
            description: 'SCRUM Planning',
            start: '2024-11-13T09:11:35.126Z',
            end: '2024-11-13T11:26:35.126Z',
            title: 'Repeating Event'
          },
          {
            id: '5e8882fcd525e076b3c1542c',
            allDay: true,
            color: '#faad14',
            description: 'Sorry, John!',
            start: '2024-11-17T14:11:35.126Z',
            end: '2024-11-17T14:26:35.126Z',
            title: 'Conference'
          },
          {
            id: '5e8882e440f6322fa399eeb8',
            allDay: true,
            color: '#52c41a',
            description: 'Inform about new contract',
            start: '2024-11-22T14:56:35.126Z',
            end: '2024-11-21T14:56:35.126Z',
            title: 'All Day Event'
          },
          {
            id: '5e8882fcd525e076b3c1542d',
            allDay: false,
            color: '#f6ffed',
            textColor: '#52c41a',
            description: 'Sorry, Stebin Ben!',
            start: '2024-11-23T09:56:35.126Z',
            end: '2024-11-23T13:26:35.126Z',
            title: 'Opening Ceremony'
          },
          {
            id: '5e8882eb5f8ec686220ff138',
            allDay: true,
            color: '#8c8c8c',
            description: 'Discuss about new partnership',
            start: '2024-11-21T14:56:35.126Z',
            end: '2024-11-23T13:56:35.126Z',
            title: 'Long Event'
          },
          {
            id: '5e88830672d089c53c46ece3',
            allDay: false,
            description: 'Get a new quote for the payment processor',
            start: '2024-11-25T06:30:35.126Z',
            end: '2024-11-25T08:30:35.126Z',
            title: 'Breakfast'
          },
          {
            id: '5e888302e62149e4b49aa609',
            allDay: false,
            color: '#fffbe6',
            textColor: '#faad14',
            description: 'Discuss about the new project',
            start: '2024-11-25T09:45:35.126Z',
            end: '2024-11-25T15:30:35.126Z',
            title: 'Meeting'
          },
          {
            id: '5e888302e62149e4b49aa709',
            allDay: false,
            color: '#f5222d',
            description: "Let's Go",
            start: '2024-11-25T09:00:35.126Z',
            end: '2024-11-25T11:30:35.126Z',
            title: 'Anniversary Celebration'
          },
          {
            id: '5e888302e69651e4b49aa609',
            allDay: false,
            description: 'Discuss about the new project',
            start: '2024-11-26T20:21:35.126Z',
            end: '2024-11-26T20:51:35.126Z',
            title: 'Send Gift'
          },
          {
            id: '5e8883062k8149e4b49aa709',
            allDay: false,
            color: '#faad14',
            description: "Let's Go",
            start: '2024-11-26T18:41:35.127Z',
            end: '2024-11-26T20:11:35.127Z',
            title: 'Birthday Party'
          },
          {
            id: '5e8882f1f0c9216396e05a9b',
            allDay: false,
            color: '#8c8c8c',
            description: 'SCRUM Planning',
            start: '2024-11-26T18:26:35.127Z',
            end: '2024-11-26T19:26:35.127Z',
            title: 'Repeating Event'
          },
          {
            id: '5e888302e62149e4b49aa610',
            allDay: false,
            color: '#f5222d',
            description: "Let's Go",
            start: '2024-11-26T18:41:35.127Z',
            end: '2024-11-26T19:46:35.127Z',
            title: 'Dinner'
          },
          {
            id: '5e8882eb5f8ec686220ff131',
            allDay: true,
            description: 'Discuss about new partnership',
            start: '2024-11-30T14:56:35.127Z',
            end: '2024-12-03T15:56:35.127Z',
            title: 'Long Event'
          },
          {
            id: '5e888302e62349e4b49aa609',
            allDay: false,
            color: '#1890ff',
            textColor: '#e6f7ff',
            description: 'Discuss about the project launch',
            start: '2024-12-01T15:11:35.127Z',
            end: '2024-12-01T15:16:35.127Z',
            title: 'Meeting'
          },
          {
            id: '5e888302e62149e4b49ab609',
            allDay: false,
            color: '#52c41a',
            description: 'Discuss about the tour',
            start: '2024-12-07T18:41:35.127Z',
            end: '2024-12-07T19:46:35.127Z',
            title: 'Happy Hour'
          }
        ])
      );
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function createEvent(newEvent) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = await axios.post('/api/calendar/events/add', newEvent);
      dispatch(calendar.actions.createEvent(response.data));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        update: updateEvent
      });
      dispatch(calendar.actions.updateEvent(response.data.events));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function deleteEvent(eventId) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(calendar.actions.deleteEvent({ eventId }));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function selectRange(start, end) {
  return async () => {
    dispatch(
      calendar.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
