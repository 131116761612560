import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { Dialog, Grid, InputLabel, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// project-imports
import ProjectSelect from 'components/select-boxes/ProjectSelect';
import CompanyTypeSelect from 'components/select-boxes/CompanyTypeSelect';
import DateSelect from 'components/select-boxes/DateSelect';
import useSavedFilters from 'hooks/sessionStorage/useSavedFilters';
import FilterSelect from 'components/select-boxes/FilterSelect';
import IconButton from 'components/@extended/IconButton';
import { Add, ArchiveAdd, ArchiveMinus, Edit, FilterSquare, SaveRemove } from 'iconsax-react';
import CustomTooltip from 'components/@extended/Tooltip';
import { enqueueSnackbar } from 'notistack';
import MainCard from 'components/MainCard';
import { PopupTransition } from 'components/@extended/Transitions';
import FilterCreation from 'sections/forms/validation/FilterCreation';
import UserSelect from 'components/select-boxes/UserSelect';
import CompanySelect from 'components/select-boxes/CompanySelect';
import RoleSelect from 'components/select-boxes/RoleSelect';
import ApproveLevelSelect from 'components/select-boxes/ApproveLevelSelect';
import StorageSelect from 'components/select-boxes/StorageSelect';
import ProductSelect from 'components/select-boxes/ProductSelect';
import PaymentTypeSelect from 'components/select-boxes/PaymentTypeSelect';
import HTTPMethodSelect from 'components/select-boxes/HTTPMethodSelect';
import NotificationTypeSelect from 'components/select-boxes/NotificationTypeSelect';
import useSelectedProject from 'hooks/sessionStorage/useSelectedProject';
import ProductTypeSelect from 'components/select-boxes/ProductTypeSelect';
import useSelectedCompany from 'hooks/sessionStorage/useSelectedCompany';

// third-party

const allowedFilters = {
  access: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    }
  ],

  buy_requests: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'storage_id',
      label: 'Depo',
      type: 'select_storage'
    },
    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    }
  ],
  companies: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'type',
      label: 'Firma Tipi',
      type: 'select_company_type'
    }
  ],
  company_contacts: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    }
  ],
  contracts: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'subcontractor_id',
      label: 'Yüklenici Firma',
      type: 'select_subcontractor'
    },
    {
      name: 'contractor_id',
      label: 'Sözleşme Yapan Firma',
      type: 'select_contractor'
    }
  ],
  documents: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    }
  ],
  logs: [
    {
      name: 'type',
      label: 'Method',
      type: 'select_http_method'
    },
    {
      name: 'created_by',
      label: 'Kullanıcı',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Zaman',
      type: 'date_range'
    }
  ],
  notifications: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'type',
      label: 'Bildirim Tipi',
      type: 'select_notification_type'
    }
  ],
  offers: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },

    {
      name: 'supplier_id',
      label: 'Tedarikçi',
      type: 'select_company'
    },
    {
      name: 'demanding_id',
      label: 'Talep Eden Firma',
      type: 'select_contractor'
    },
    {
      name: 'payment_type',
      label: 'Ödeme Tipi',
      type: 'select_payment_type'
    },

    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    }
  ],
  orders: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },

    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'supplier_id',
      label: 'Tedarikçi',
      type: 'select_company'
    },
    {
      name: 'payment_type',
      label: 'Ödeme Tipi',
      type: 'select_payment_type'
    },

    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    }
  ],
  hakedisler: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'company_id',
      label: 'İşveren Firma',
      type: 'select_company'
    },
    {
      name: 'subcontractor_id',
      label: 'Yüklenici Firma',
      type: 'select_subcontractor'
    }
  ],

  intakes: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'tally_date',
      label: 'İrsaliye Tarihi',
      type: 'date_range'
    },
    {
      name: 'delivery_time',
      label: 'Teslim Zamanı',
      type: 'date_range'
    },

    {
      name: 'recipient_id',
      label: 'Teslim Alan',
      type: 'select_user'
    }
  ],
  personnels: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'user_id',
      label: 'Çalışan',
      type: 'select_user'
    },

    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    }
  ],
  products: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    },
    {
      name: 'product_type',
      label: 'Ürün Tipi',
      type: 'select_product_type'
    }
  ],
  requested_products: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'product_id',
      label: 'Ürün',
      type: 'select_product'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'storage_id',
      label: 'Depo',
      type: 'select_storage'
    },
    {
      name: 'product_type',
      label: 'Ürün Tipi',
      type: 'select_product_type'
    }
  ],
  stocks: [
    {
      name: 'product_id',
      label: 'Ürün',
      type: 'select_product'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'storage_id',
      label: 'Depo',
      type: 'select_storage'
    },
    {
      name: 'product_type',
      label: 'Ürün Tipi',
      type: 'select_product_type'
    } /* Çalışmıyor şuan anlamadım */
  ],
  storage_snapshots: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },
    {
      name: 'storage_id',
      label: 'Depo',
      type: 'select_storage'
    },
    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    }
  ],

  quotations: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'supplier_id',
      label: 'Tedarikçi',
      type: 'select_subcontractor'
    },
    {
      name: 'demanding_id',
      label: 'Talep Eden',
      type: 'select_contractor'
    }
  ],

  vaults: [
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'company_id',
      label: 'Firma',
      type: 'select_company'
    },
    {
      name: 'project_id',
      label: 'Proje',
      type: 'select_project'
    },

    {
      name: 'approve_level',
      label: 'Onay Seviyesi',
      type: 'select_approve_level'
    }
  ],

  users: [
    {
      name: 'created_by',
      label: 'Oluşturan',
      type: 'select_user'
    },
    {
      name: 'timestamp',
      label: 'Oluşturma Zamanı',
      type: 'date_range'
    },
    {
      name: 'role_id',
      label: 'Roll',
      type: 'select_roles'
    }
  ]
};

function FilterDrawerContent({ table_name, filters, setFilters }) {
  const theme = useTheme();
  const savedFilters = useSavedFilters();
  const [selectedFilters, setSelectedFilters] = useState(null);

  useEffect(() => {
    if (savedFilters && savedFilters[table_name]) {
      handleFilterChange(savedFilters[table_name]?.find((item) => item?.default) || null);
    }
  }, [table_name, savedFilters]);

  const _selectedProject = useSelectedProject();
  const _selectedCompany = useSelectedCompany();

  const tablesThatUsesProjectFilter = [
    'documents',
    'buy_requests',
    'hakedisler',
    'requested_products',
    'intakes',
    'requested_products',
    'contracts',
    'offers',
    'orders',
    'storages',
    'stocks',
    'personnels'
  ];
  useEffect(() => {
    if (tablesThatUsesProjectFilter.includes(table_name)) {
      setProject(_selectedProject);
    }
  }, [_selectedProject]);

  useEffect(() => {
    console.log('_selectedCompany', _selectedCompany);
    if (table_name === 'hakedisler' && _selectedCompany) {
      setSubcontractor(_selectedCompany);
    }
  }, [_selectedCompany]);

  const [startDate, setStartDate] = useState(filters?.startDate || null);
  const [endDate, setEndDate] = useState(filters?.endDate || null);
  const [user, setUser] = useState(filters?.user || null);
  const [storage, setStorage] = useState(filters?.storage || null);
  const [product, setProduct] = useState(filters?.product || null);
  const [project, setProject] = useState(filters?.project || null);
  const [company, setCompany] = useState(filters?.company || null);
  const [contractor, setContractor] = useState(filters?.contractor || null);
  const [subcontractor, setSubcontractor] = useState(filters?.subcontractor || null);
  const [companyType, setCompanyType] = useState(filters?.companyType || null);
  const [paymentType, setPaymentType] = useState(filters?.paymentType || null);
  const [httpMethodType, setHttpMethodType] = useState(filters?.httpMethodType || null);
  const [notificationType, setNotificationType] = useState(filters?.notificationType || null);
  const [productType, setProductType] = useState(filters?.productType || null);
  const [roles, setRoles] = useState(filters?.role || null);
  const [approveLevel, setApproveLevel] = useState(filters?.approveLevel || null);

  useEffect(() => {
    const payload = {
      startDate,
      endDate,
      user,
      storage,
      product,
      project,
      company,
      contractor,
      subcontractor,
      companyType,
      paymentType,
      httpMethodType,
      notificationType,
      productType,
      roles,
      approveLevel
    };

    // remove null values
    Object.keys(payload).forEach((key) => payload[key] == null && delete payload[key]);
    setFilters && setFilters(payload);
  }, [
    startDate,
    endDate,
    user,
    storage,
    product,
    project,
    company,
    contractor,
    subcontractor,
    companyType,
    paymentType,
    httpMethodType,
    notificationType,
    productType,
    roles,
    approveLevel
  ]);

  const allowed = allowedFilters[table_name] || [];

  const handleFilterChange = (newValue, resetOnlySavedFilter) => {
    setSelectedFilters(newValue);
    if (resetOnlySavedFilter) {
      return;
    }
    console.log('handleFilterChange newValue', newValue);
    const filters = newValue?.filters;
    if (filters) {
      setStartDate(filters?.startDate || null);
      setEndDate(filters?.endDate || null);
      setUser(filters?.user || null);
      setStorage(filters?.storage || null);
      setProduct(filters?.product || null);
      setProject(filters?.project || null);
      setCompany(filters?.company || null);
      setContractor(filters?.contractor || null);
      setSubcontractor(filters?.subcontractor || null);
      setCompanyType(filters?.companyType || null);
      setPaymentType(filters?.paymentType || null);
      setHttpMethodType(filters?.httpMethodType || null);
      setNotificationType(filters?.notificationType || null);
      setProductType(filters?.productType || null);
      setRoles(filters?.role || null);
      setApproveLevel(filters?.approveLevel || null);
    } else {
      resetFilters();
    }
  };

  const resetFilters = () => {
    setSelectedFilters(null);
    setStartDate(null);
    setEndDate(null);
    setUser(null);
    setStorage(null);
    setProduct(null);
    setProject(null);
    setCompany(null);
    setContractor(null);
    setSubcontractor(null);
    setCompanyType(null);
    setPaymentType(null);
    setHttpMethodType(null);
    setNotificationType(null);
    setProductType(null);
    setRoles(null);
    setApproveLevel(null);
  };

  const [creationVisible, setCreationVisible] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(null);

  useEffect(() => {
    if (!creationVisible) {
      setItemToUpdate(null);
    }
  }, [creationVisible]);
  return (
    <Grid container spacing={2}>
      <Dialog
        maxWidth="sm"
        scroll="body"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          setCreationVisible(false);
        }}
        open={creationVisible}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <FilterCreation itemToUpdate={itemToUpdate} table_name={table_name} setCreationVisible={setCreationVisible} />
      </Dialog>
      {Object.keys(filters).length > 0 && (
        <Grid item xs={12}>
          <MainCard sx={{ p: 1 }} content={false}>
            <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <FilterSquare variant="TwoTone" color={theme.palette.primary.main} />
              <Typography variant="h6">Şu anda {Object.keys(filters).length} filtre uygulanıyor</Typography>
              <CustomTooltip title="Filtreleri Sıfırla" arrow>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    resetFilters(null);
                  }}
                  color="secondary"
                  sx={{ ml: 'auto' }}
                >
                  <Add variant="Linear" style={{ transform: 'rotate(45deg)' }} />
                </IconButton>
              </CustomTooltip>
            </Stack>
          </MainCard>
        </Grid>
      )}
      <>
        <Grid item xs={12}>
          <InputLabel>Kayıtlı Filtreler</InputLabel>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FilterSelect
              table_name={table_name}
              selectedFilters={selectedFilters}
              setSelectedFilters={(newValue) => {
                handleFilterChange(newValue);
              }}
            />
            {selectedFilters && (
              <Stack spacing={0} direction={'row'} alignItems={'center'}>
                <CustomTooltip
                  title={
                    selectedFilters.default
                      ? 'Varsayılan filtreyi iptal et. (Bu seçenek varsayılarn filtre özelliğini kaldıracağından her ekran yenilemesinde tekrar filtreleme yapmanız gerekebilir.)'
                      : 'Varsayılan filtre olarak işaretle. (Bu seçenek bu ayarların her ekran açılışında yüklenmesini sağlar.)'
                  }
                  arrow
                  color="secondary"
                >
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();

                      // set selected filter as default ( make its attrubute default: true and every other filter false) if it is default set it to false

                      const newFilters = savedFilters[table_name].map((item) => {
                        if (item.id === selectedFilters.id) {
                          return {
                            ...item,
                            default: !selectedFilters.default
                          };
                        } else {
                          return {
                            ...item,
                            default: false
                          };
                        }
                      });
                      sessionStorage.setItem(
                        'savedFilters',
                        JSON.stringify({
                          ...savedFilters,
                          [table_name]: newFilters
                        })
                      );
                      window.dispatchEvent(new Event('storage'));
                      enqueueSnackbar(
                        `${!selectedFilters.default ? 'Varsayılan filtre olarak işaretlendi' : 'Varsayılan filtre özelliği kaldırıldı'}`,
                        {
                          variant: 'success'
                        }
                      );
                    }}
                    color="warning"
                    sx={{ ml: 'auto' }}
                  >
                    {selectedFilters.default ? <ArchiveMinus /> : <ArchiveAdd />}
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title="Filtreyi Yeniden Adlandır" arrow color="secondary">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('clicked save filters', savedFilters);
                      setItemToUpdate(selectedFilters);
                      setCreationVisible(true);
                    }}
                    color="secondary"
                    sx={{ ml: 'auto' }}
                  >
                    <Edit variant="Outline" />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title="Filtreyi Sil" arrow color="error">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      // delete the selected filter
                      sessionStorage.setItem(
                        'savedFilters',
                        JSON.stringify({
                          ...savedFilters,
                          [table_name]: savedFilters[table_name].filter((item) => item.id !== selectedFilters.id)
                        })
                      );
                      window.dispatchEvent(new Event('storage'));
                      enqueueSnackbar(`Filtre başarıyla silindi`, { variant: 'success' });
                      handleFilterChange(null);
                    }}
                    color="error"
                    sx={{ ml: 'auto' }}
                  >
                    <SaveRemove variant="Outline" />
                  </IconButton>
                </CustomTooltip>
              </Stack>
            )}
          </Stack>
        </Grid>
      </>

      {allowed.map((filter) => {
        switch (filter.type) {
          case 'date_range':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <DateSelect value={startDate} onChange={setStartDate} label="Bu Tarihten İtibaren" />
                </Grid>
                <Grid key={`${table_name}-${filter.type}-3`} item xs={12} lg={12}>
                  <DateSelect value={endDate} onChange={setEndDate} label="Bu Tarihe Kadar" />
                </Grid>
              </>
            );
          case 'select_user':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <UserSelect
                    selectedUser={user}
                    setSelectedUser={setUser}
                    onChange={() => {
                      console.log('user selected');
                    }}
                  />
                </Grid>
              </>
            );

          case 'select_product':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <ProductSelect
                    selectedProduct={product}
                    setSelectedProduct={setProduct}
                    onChange={() => {
                      console.log('product selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_project':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <ProjectSelect
                    selectedProject={project}
                    setSelectedProject={setProject}
                    onChange={() => {
                      console.log('project selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_storage':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <StorageSelect
                    selectedStorage={storage}
                    setSelectedStorage={setStorage}
                    onChange={() => {
                      console.log('storage selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_company':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <CompanySelect
                    selectedCompany={company}
                    setSelectedCompany={setCompany}
                    onChange={() => {
                      console.log('company selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_contractor':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <CompanySelect
                    selectedCompany={contractor}
                    setSelectedCompany={setContractor}
                    onChange={() => {
                      console.log('contractor selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_subcontractor':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <CompanySelect
                    selectedCompany={subcontractor}
                    setSelectedCompany={setSubcontractor}
                    onChange={() => {
                      console.log('subcontractor selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_company_type':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <CompanyTypeSelect
                    selectedCompanyType={companyType}
                    setSelectedCompanyType={setCompanyType}
                    onChange={() => {
                      console.log('company type selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_payment_type':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <PaymentTypeSelect
                    selectedPaymentType={paymentType}
                    setSelectedPaymentType={setPaymentType}
                    onChange={() => {
                      console.log('payment type selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_http_method':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <HTTPMethodSelect
                    selectedMethod={httpMethodType}
                    setSelectedMethod={setHttpMethodType}
                    onChange={() => {
                      console.log('http method selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_notification_type':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <NotificationTypeSelect
                    selectedNotificationType={notificationType}
                    setSelectedNotificationType={setNotificationType}
                    onChange={() => {
                      console.log('notification type selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_product_type':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <ProductTypeSelect
                    selectedProductType={productType}
                    setSelectedProductType={setProductType}
                    onChange={() => {
                      console.log('product type selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_roles':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <RoleSelect
                    isMultiple={true}
                    selectedRole={roles || []}
                    setSelectedRole={setRoles}
                    onChange={() => {
                      console.log('roles selected');
                    }}
                  />
                </Grid>
              </>
            );
          case 'select_approve_level':
            return (
              <>
                <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                  <InputLabel>{filter.label}</InputLabel>
                </Grid>
                <Grid key={`${table_name}-${filter.type}-2`} item xs={12} lg={12}>
                  <ApproveLevelSelect
                    selectedApproveLevel={approveLevel}
                    setSelectedApproveLevel={setApproveLevel}
                    onChange={() => {
                      console.log('approve level selected');
                    }}
                  />
                </Grid>
              </>
            );
          default:
            return (
              <Grid key={`${table_name}-${filter.type}-1`} item xs={12}>
                <Typography color={'error'} variant="h5">
                  Filter type not found
                </Typography>
              </Grid>
            );
        }
      })}
      <Grid item xs={12} height={200}></Grid>
    </Grid>
  );
}

FilterDrawerContent.propTypes = {
  filter: PropTypes.object,
  initialState: PropTypes.object,
  handleDrawerOpen: PropTypes.func,
  openFilterDrawer: PropTypes.bool,
  setFilter: PropTypes.func,
  setLoading: PropTypes.func
};

export default FilterDrawerContent;
