import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    const savedRoutes = JSON.parse(localStorage.getItem('recentRoutes')) || {};

    const currentRoute = location.pathname;
    const now = new Date().toISOString();

    // Update or initialize route data
    if (!savedRoutes[currentRoute]) {
      savedRoutes[currentRoute] = { count: 1, lastVisit: now };
    } else {
      savedRoutes[currentRoute] = {
        ...savedRoutes[currentRoute],
        count: savedRoutes[currentRoute].count + 1,
        lastVisit: now
      };
    }

    // Save updated data back to localStorage
    localStorage.setItem('recentRoutes', JSON.stringify(savedRoutes));
    window.dispatchEvent(new Event('storage')); // Trigger storage event
  }, [location.pathname]); // Runs whenever the route changes

  return null; // No UI component
}

export default RouteTracker;
