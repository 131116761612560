import { useState, useEffect } from 'react';

function useRecentRoutes(limit = 10) {
  const [recentRoutes, setRecentRoutes] = useState([]);

  useEffect(() => {
    // Function to load the selected project from session storage
    const loadRecentRoutes = () => {
      const savedRoutes = JSON.parse(localStorage.getItem('recentRoutes')) || {};

      const sortedRoutes = Object.entries(savedRoutes)
        .filter(([path]) => path !== '/404') // Exclude the 404 route
        .sort(([, a], [, b]) => new Date(b.lastVisit) - new Date(a.lastVisit))
        .slice(0, limit); // Limit the number of routes

      setRecentRoutes(sortedRoutes);
    };

    // Load the selected project when the component mounts
    loadRecentRoutes();

    // Add an event listener for storage changes
    window.addEventListener('storage', loadRecentRoutes);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', loadRecentRoutes);
    };
  }, []);

  return recentRoutes;
}

export default useRecentRoutes;
