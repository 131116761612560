import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Stack, Typography, Box, Dialog /* DialogContent */ } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import Avatar from 'components/@extended/Avatar';
import { ThemeMode } from 'config';

// third-party
import { FormattedRelativeTime } from 'react-intl';

// assets
import { ArrowCircleRight } from 'iconsax-react';
import { getContrastColor } from 'utils/utkuFunctions';
import BuyRequestProductsTable from 'sections/tables/react-table/BuyRequestProductsTable';
import SyntaxHighlight from 'utils/SyntaxHighlight';
import OfferedProductsTable from 'sections/tables/react-table/OfferedProductsTable';
import AnimateButton from './@extended/AnimateButton';
import { PopupTransition } from './@extended/Transitions';
import ExpenseTable from 'sections/tables/react-table/ExpenseTable';

const special_keys = ['products', 'offered_products', 'expenses'];

// Helper function for formatting changes
const formatChange = (key, value) => {
  if (Array.isArray(value)) {
    if (key === 'products') {
      return <BuyRequestProductsTable compact={true} products={value} />;
    } else if (key === 'offered_products') {
      return <OfferedProductsTable readOnly data={value} />;
    } else if (key === 'expenses') {
      return <ExpenseTable readOnly expenses={value} />;
    }

    return (
      <Grid container spacing={0}>
        {value.map((item, index) => (
          <Grid item key={index} xs={12} p={0}>
            <SyntaxHighlight language="javascript">{JSON.stringify(item, null, 2)}</SyntaxHighlight>
          </Grid>
        ))}
      </Grid>
    );
  } else if (typeof value === 'object' && value !== null) {
    return (
      <Box sx={{ p: 1, borderRadius: 1, bgcolor: 'background.paper' }}>
        <SyntaxHighlight language="javascript">{JSON.stringify(value, null, 2)}</SyntaxHighlight>
      </Box>
    );
  } else if (key === 'color') {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box sx={{ paddingX: 1, height: 24, backgroundColor: value, borderRadius: 1 }}>
          <Typography color={getContrastColor(value)} variant="body1">
            {value}
          </Typography>
        </Box>
      </Stack>
    );
  } else {
    return <Typography variant="body1">{value}</Typography>;
  }
};

function UpdateLogCardContent({ updateLog, ...props }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Avatar
              sx={{
                width: 24,
                height: 24
              }}
              size="sm"
              alt="User 1"
              src={process.env.REACT_APP_API_URL + updateLog.user.avatar_url}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Grid container alignItems="center" spacing={1} justifyContent="space-between">
              <Grid item>
                <Typography
                  noWrap
                  align="left"
                  color={props.primary.main}
                  variant="subtitle1"
                  component="div"
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {updateLog.user.name}
                  <Typography
                    color={props.main}
                    variant="subtitle2"
                    sx={{
                      ml: 1
                    }}
                  >
                    {updateLog.changes?.length} alanda değişiklik yaptı
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Dot
                    size={6}
                    sx={{
                      mt: -0.25
                    }}
                    color="secondary"
                  />
                  <Typography variant="caption" color="secondary">
                    <FormattedRelativeTime
                      value={(new Date(updateLog.timestamp).getTime() - new Date(Date.now()).getTime()) / 1000}
                      updateIntervalInSeconds={1}
                    />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {updateLog.changes.map((change) => {
            const from = formatChange(change.key, change.from);
            const to = formatChange(change.key, change.to);
            const isObjectArray = Array.isArray(change.from) || Array.isArray(change.to);
            const keyIsSpecial = special_keys.includes(change.key);
            const maxElements = Math.max(
              Array.isArray(change.from) ? change.from.length : 0,
              Array.isArray(change.to) ? change.to.length : 0
            );
            return (
              <Grid key={change.key} item xs={12}>
                <MainCard
                  title={<Typography variant="subtitle1">{change.translation}</Typography>}
                  content={false}
                  sx={{
                    mb: 1.25,
                    overflowX: 'auto', // Enable horizontal scrolling
                    flexShrink: 0
                  }}
                >
                  {isObjectArray && !keyIsSpecial ? (
                    <Accordion defaultExpanded={false}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant="subtitle1">{`Göster (${maxElements})`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={0.2}>
                          <Grid
                            item
                            xs={5.5}
                            sx={{
                              overflowX: 'auto', // Ensure scrolling if content overflows
                              minWidth: 300 // Optional: Adjust as needed for content size
                            }}
                          >
                            <Stack alignItems="center" justifyContent="center">
                              {from}
                            </Stack>
                          </Grid>
                          <Grid item xs={1}>
                            <Stack sx={{ pt: 0.5 }} direction="row" alignItems="center" justifyContent="center">
                              <AnimateButton type="slide" direction="right" scale={2}>
                                <ArrowCircleRight color={props.primary.main} variant="Linear" />
                              </AnimateButton>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={5.5}
                            sx={{
                              overflowX: 'auto', // Same as above for horizontal scrolling
                              minWidth: 300 // Match with 'from' for consistency
                            }}
                          >
                            <Stack alignItems="center" justifyContent="center">
                              {to}
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Grid container alignItems="center" spacing={0.2}>
                      <Grid
                        item
                        xs={5.75}
                        sx={{
                          overflowX: 'auto', // Allow horizontal scrolling
                          minWidth: 300 // Ensure content fits without shrinking
                        }}
                      >
                        <Stack alignItems="center" justifyContent="center" flexShrink={0}>
                          {from}
                        </Stack>
                      </Grid>
                      <Grid item xs={0.5}>
                        <Stack sx={{ pt: 0.5 }} direction="row" alignItems="center" justifyContent="center">
                          <AnimateButton type="slide" direction="right" scale={2}>
                            <ArrowCircleRight color={props.primary.main} variant="Linear" />
                          </AnimateButton>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={5.75}
                        sx={{
                          overflowX: 'auto', // Same behavior for 'to' content
                          minWidth: 300 // Match with 'from' for balance
                        }}
                      >
                        <Stack alignItems="center" justifyContent="center">
                          {to}
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </MainCard>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

const UpdateLogCard = ({ updateLog }) => {
  const theme = useTheme();
  const user = updateLog.user;
  const changes = updateLog.changes;

  // State for dialog visibility
  const [open, setOpen] = useState(false);

  // Handlers for opening/closing dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Main Card */}
      <MainCard
        content={false}
        onClick={handleOpen} // Optional hover functionality
        sx={{
          background: theme.palette.mode === ThemeMode.DARK ? theme.palette.warning[100] : theme.palette.secondary.lighter,
          p: 1.5,
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        <UpdateLogCardContent
          primary={theme.palette.primary}
          main={theme.palette.secondary.main}
          handleOpen={handleOpen}
          updateLog={updateLog}
        ></UpdateLogCardContent>
      </MainCard>

      {/* Fullscreen Dialog */}
      <Dialog
        maxWidth={false}
        open={open}
        onClose={handleClose}
        TransitionComponent={PopupTransition}
        scroll="body"
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            p: 0,
            maxHeight: '90vh',
            overflow: 'auto'
          }
        }}
      >
        <MainCard
          content={false}
          sx={{
            background: theme.palette.mode === ThemeMode.DARK ? theme.palette.warning[100] : theme.palette.secondary.lighter,
            p: 1.5,
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          <UpdateLogCardContent
            primary={theme.palette.primary}
            main={theme.palette.secondary.main}
            user={user}
            changes={changes}
            handleOpen={handleOpen}
            updateLog={updateLog}
          />
        </MainCard>
      </Dialog>
    </>
  );
};

UpdateLogCard.propTypes = {
  updateLog: PropTypes.object
};

export default UpdateLogCard;
