import { useState } from 'react';

// material-ui
import { TextField, Autocomplete, ListItem, ListItemText, Chip, Stack, CircularProgress, Typography, Button, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutoCompleteCompanies } from 'hooks/company/useCompanyData';
import { useAsyncDebounce } from 'react-table';
import AlertClosing from 'components/alerts/AlertClosing';
import usePreventNavigation from 'hooks/usePreventNavigation';
import CompanyCreationForm from 'sections/forms/validation/CompanyCreationForm';
import { PopupTransition } from 'components/@extended/Transitions';
import CompanyCell from 'components/data/CompanyCell';

// third-party
import { getIn } from 'formik';

// assets
import { Add, Copyright } from 'iconsax-react';
import CustomTooltip from 'components/@extended/Tooltip';

export const companyColor = (type) => {
  switch (type) {
    case 'Ana Firma':
      return 'primary';
    case 'Ortaklık':
      return 'info';
    case 'Taşeron':
      return 'warning';
    case 'Tedarikçi':
      return 'success';
    case 'Tşrn-Tdrk':
      return 'warning';
    case 'İdari':
      return 'error';
    default:
      return 'secondary';
  }
};

function CompanySelect({ id, extraParams, label, selectedCompany, setSelectedCompany, onChange, formik, disabled }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutoCompleteCompanies({ search, isFocused, ...extraParams });
  const companies = data?.data || [];
  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 200);

  const [creationVisible, setCreationVisible] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  usePreventNavigation(isChanged);

  return !disabled ? (
    <>
      <AlertClosing
        open={alertVisible}
        onAgree={() => {
          setAlertVisible(false);
          setIsChanged(false);
          setCreationVisible(false);
        }}
        onClose={() => setAlertVisible(false)}
        highlight="Firma Formu"
      />
      <Dialog
        maxWidth="md"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          if (isChanged) setAlertVisible(true);
          else setCreationVisible(false);
        }}
        scroll="body"
        open={creationVisible}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <CompanyCreationForm setCreationVisible={setCreationVisible} setIsChanged={setIsChanged} />
      </Dialog>
      <Autocomplete
        loading={isLoading || isFetching}
        loadingText="Firmalar yükleniyor..."
        id="company-select"
        value={selectedCompany}
        options={companies}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option) => option.id == selectedCompany?.id}
        selectOnFocus
        clearOnBlur
        autoHighlight
        handleHomeEndKeys
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
          setSelectedCompany && setSelectedCompany(newValue);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setSearch('');
        }}
        filterOptions={(options) => {
          return options;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || ''}
            placeholder={/* data?.total + ' kayıtlı firma içinden seç' */ 'Firma Ara'}
            value={params.inputProps.value}
            onChange={(e) => onSearchWordChange(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCompany && (
                <Chip label={<Typography>{selectedCompany.code}</Typography>} color={companyColor(selectedCompany.type)} />
              ),
              endAdornment: (
                <>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <CustomTooltip style={{ flexGrow: 0 }} title="Burada hızlı ekleme özelliği mevcuttur">
                      <Add />
                    </CustomTooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
            error={getIn(formik?.errors, id) && getIn(formik.touched, id)}
            helperText={getIn(formik?.errors, id) && getIn(formik.touched, id) && getIn(formik?.errors, id)}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" columnGap={1} width="100%">
              <Stack direction="row" alignItems="center" columnGap={1}>
                <Chip label={<Typography>{option.code}</Typography>} color={companyColor(option.type)} />
                <ListItemText
                  primary={option.name}
                  primaryTypographyProps={{
                    color: theme.palette.text.primary
                  }}
                />
              </Stack>
              <Chip label={<Typography>{option.type}</Typography>} color={companyColor(option.type)} size="small" />
            </Stack>
          </ListItem>
        )}
        noOptionsText={
          <Stack alignItems="center" spacing={1}>
            <Typography>Firma bulunamadı</Typography>

            <Button startIcon={<Copyright />} variant="contained" color="primary" onClick={() => setCreationVisible(true)}>
              Yeni Firma Tanımla
            </Button>
          </Stack>
        }
      />
    </>
  ) : (
    <CompanyCell company={selectedCompany} />
  );
}

export default CompanySelect;
