import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// material-ui
import { ListItem, ListItemText, Stack, Typography, LinearProgress, Chip, Alert } from '@mui/material';

// third-party
import { FormattedDate } from 'react-intl';
import CustomTooltip from 'components/@extended/Tooltip';
import { enqueueSnackbar } from 'notistack';

const calculateProgress = (created_at, expiration) => {
  const createdTime = new Date(created_at).getTime();
  const expirationTime = expiration ? new Date(expiration).getTime() : createdTime + 24 * 60 * 60 * 1000;
  const currentTime = Date.now();
  const progress = ((currentTime - createdTime) / (expirationTime - createdTime)) * 100;
  return Math.min(100, progress);
};

const calculateRemainingTimeInMilliseconds = (expiration) => {
  const expirationTime = new Date(expiration).getTime();
  return Math.max(0, expirationTime - Date.now());
};

const getProgressColor = (progress) => {
  if (progress >= 90) return 'error';
  if (progress >= 70) return 'warning';
  if (progress >= 30) return 'primary';
  return 'success';
};

function RequestItem({ request }) {
  const navigate = useNavigate();
  // check if the url is /teklif/:nano_id
  const url = window.location.pathname;
  const isViewingFromInside = url.startsWith('/create-offer/');

  const expirationTime = request.expiration || new Date(request.created_at).getTime() + 24 * 60 * 60 * 1000;

  const [progress, setProgress] = useState(calculateProgress(request.created_at, expirationTime));
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTimeInMilliseconds(expirationTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(calculateProgress(request.created_at, expirationTime));
      setRemainingTime(calculateRemainingTimeInMilliseconds(expirationTime));
    }, 1000);
    return () => clearInterval(timer);
  }, [request.created_at, expirationTime]);

  const formatRemainingTime = () => {
    const totalSeconds = Math.floor(remainingTime / 1000);
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}gün ${hours}sa ${minutes}dk ${seconds}sn`;
  };
  console.log('request', request);
  return (
    <ListItem
      divider
      sx={{
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'background.default'
        },
        '&:active': {
          bgcolor: 'background.default'
        }
      }}
      onClick={() => {
        if (request.active_product_missing) {
          enqueueSnackbar('Bazı ürünler artık talep edilmiyor veya başka bir tedarikçi tarafından karşılanmış', { variant: 'error' });
        } else if (!isViewingFromInside) {
          navigate(`/teklif/${request.nano_id}`);
        } else {
          if (request.offer_id) navigate(`/offers/${request.offer_id}`);
          else navigate(`/create-offer/${request.nano_id}`);
        }
      }}
    >
      <Stack spacing={1} width="100%">
        <ListItemText
          primary={`Teklif Talebi #${request.id}`}
          secondary={<FormattedDate value={request.created_at} year="numeric" month="long" day="2-digit" />}
        />
        {request.offer_id ? (
          <Chip sx={{ flexGrow: 0 }} label={`Teklif #${request.offer_id}`} color="primary" variant="contained" />
        ) : remainingTime <= 11 ? (
          <Chip label="Süresi Geçti" color="error" variant="contained" />
        ) : request.active_product_missing ? (
          <Alert severity="error">Bazı ürünler artık talep edilmiyor veya başka bir tedarikçi tarafından karşılanmış</Alert>
        ) : (
          <>
            <CustomTooltip
              arrow
              placement="right"
              title={
                <Stack direction="row" alignItems={'center'}>
                  <Typography variant="body2">Son Cevap Tarihi</Typography>
                  <Stack sx={{ width: 23 }}></Stack>
                  <Typography variant="body2">
                    <FormattedDate
                      value={request.expiration}
                      year="numeric"
                      month="long"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                      second="2-digit"
                    />
                  </Typography>
                </Stack>
              }
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  Kalan Süre:
                </Typography>

                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold', opacity: 0.7 }}>
                  {formatRemainingTime()}
                </Typography>
              </Stack>
            </CustomTooltip>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 8, borderRadius: 5, bgcolor: `${getProgressColor(progress)}.light` }}
              color={getProgressColor(progress)}
            />
          </>
        )}
      </Stack>
    </ListItem>
  );
}

RequestItem.propTypes = {
  request: PropTypes.shape({
    name: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    expiration: PropTypes.string, // Optional expiration time
    offer_id: PropTypes.number // Optional offer_id
  }).isRequired
};

export default RequestItem;
