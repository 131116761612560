import { useState } from 'react';

// material-ui
import {
  TextField,
  Autocomplete,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  CircularProgress,
  Stack,
  Chip
  /* AvatarGroup, */
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutocompleteUsers } from 'hooks/user/useUsersData';
import Avatar from 'components/@extended/Avatar';
import UserCell from 'components/data/UserCell';

// third-party
import { useAsyncDebounce } from 'react-table';

function UserSelect({ selectedUser, setSelectedUser, onChange, label, multiple = false, ...props }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutocompleteUsers({ search, isFocused });
  const users = data?.data || [];

  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 200);

  const renderSelectedUsers = () => {
    if (!multiple || !Array.isArray(selectedUser)) return null;

    // Option 1: Chips with UserCell
    return (
      <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={1} marginY={1}>
        {selectedUser.map((user) => (
          <Chip
            key={user.id}
            label={<UserCell user={user} />}
            onDelete={() => {
              if (props?.disabled) return;
              const newSelection = selectedUser.filter((u) => u.id !== user.id);
              if (setSelectedUser) setSelectedUser(newSelection);
              if (onChange) onChange(newSelection);
            }}
          />
        ))}
      </Stack>
    );

    // Option 2: AvatarGroup
    // return (
    //   <AvatarGroup max={4}>
    //     {selectedUser.map((user) => (
    //       <Avatar key={user.id} src={process.env.REACT_APP_API_URL + user.avatar_url}>
    //         {user.name.charAt(0)}
    //       </Avatar>
    //     ))}
    //   </AvatarGroup>
    // );
  };

  return (
    <Autocomplete
      {...props}
      sx={{ minWidth: 250 }}
      multiple={multiple} // Enable multiple selection
      loading={isLoading || isFetching}
      loadingText="Yükleniyor..."
      id="user-select"
      value={selectedUser || (multiple ? [] : null)} // Adjust for single or multiple selection
      options={users}
      filterOptions={(options) => options} // Disable internal filtering
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option; // For cases when the input is just a string
        }
        return `${option.name}`; // Include both user name and role name
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (onChange) onChange(newValue);
        if (setSelectedUser) setSelectedUser(newValue); // Update selected users
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        setSearch('');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`${label || 'Kullanıcı'} Ara`}
          label={label !== undefined ? label : ''}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
          InputProps={{
            ...params.InputProps,
            startAdornment: multiple
              ? renderSelectedUsers()
              : selectedUser && (
                  <ListItemAvatar>
                    <Avatar size="sm" src={process.env.REACT_APP_API_URL + selectedUser.avatar_url}>
                      {selectedUser.name.charAt(0) + selectedUser.name.charAt(1)}
                    </Avatar>
                  </ListItemAvatar>
                ),
            endAdornment: (
              <>
                {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props}>
          {multiple && <Checkbox edge="start" checked={selected} tabIndex={-1} disableRipple />}
          <ListItemAvatar>
            <Avatar src={process.env.REACT_APP_API_URL + option.avatar_url}>{option.name.charAt(0) + option.name.charAt(1)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.name} secondary={option.role?.name} />
        </ListItem>
      )}
      noOptionsText="Kullanıcı bulunamadı"
    />
  );
}

export default UserSelect;
