import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { enqueueSnackbar } from 'notistack';

export async function getDeviceFingerprint() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
}

export function askForPermission(subscribe) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        subscribeUserToPush(registration, subscribe);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      navigator.serviceWorker.ready.then((registration) => {
        subscribeUserToPush(registration, subscribe);
      });
    } else {
      console.log('Notification permission denied.');
    }
  });
}

export function checkNotificationPermission(queryClient) {
  if (Notification.permission === 'denied') {
    enqueueSnackbar('Bildirimler engellendi. Lütfen tarayıcınızın ayarlarından bildirimleri etkinleştirin.', {
      variant: 'info',
      preventDuplicate: true
    });
    return;
  }

  if (Notification.permission === 'default') {
    queryClient.invalidateQueries('notifications');
    return;
  }
}

export function checkSubscription(userEndpoints, setIsSubscribed) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (!subscription) {
            setIsSubscribed(false);
            return;
          }

          if (userEndpoints?.includes(subscription.endpoint)) {
            setIsSubscribed(true);
          } else {
            setIsSubscribed(false);
          }
        })
        .catch((error) => {
          console.error('Failed to get the subscription:', error);
          setIsSubscribed(false);
        });
    })
    .catch((error) => {
      console.error('Service Worker is not ready or failed to access:', error);
    });
}

export function unsubscribeUser(unsubscribe, setIsSubscribed, queryClient) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (!subscription) {
            setIsSubscribed(false);
            return;
          }

          subscription
            .unsubscribe()
            .then(() => {
              console.log('User is unsubscribed.');
              unsubscribe();
              queryClient.invalidateQueries('notifications');
            })
            .catch((error) => {
              console.error('Error unsubscribing on the client side:', error);
              unsubscribe();
            });
        })
        .catch((error) => {
          console.error('Failed to get the subscription:', error);
        });
    })
    .catch((error) => {
      console.error('Service Worker is not ready or failed to access:', error);
    });
}

export function subscribeUserToPush(registration, subscribe) {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: 'BDTpzabEJ_TNs6UQZrqlAP2lRbilugKJTMrYiOz-As2ItwP1beCCffdwCI76XNJm_I4vqhW_dSkYnzLD4jDh_q8'
  };

  registration.pushManager
    .subscribe(subscribeOptions)
    .then(async (subscription) => {
      const device_fingerprint = await getDeviceFingerprint();
      const payload = { ...subscription.toJSON(), device_fingerprint };
      subscribe(payload);
    })
    .catch((err) => {
      console.error('Unable to subscribe to push', err);
    });
}
