import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, Stack } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { DRAWER_WIDTH } from 'config';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { MenuOrientation } from 'config';
import IconButton from 'components/@extended/IconButton';
import { ArrowLeft2 } from 'iconsax-react';
import { motion } from 'framer-motion';
import CustomTooltip from 'components/@extended/Tooltip';

const MainLayout = () => {
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { container, miniDrawer, menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
  const backButtonRef = useRef(null); // Reference to the back button
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
  }, [downXL]);

  const handleBackButtonClick = () => {
    history.back();
  };

  const handleMouseMove = (event) => {
    if (!backButtonRef.current) return;

    const rect = backButtonRef.current.getBoundingClientRect();
    const thresholdXMin = rect.left - 32;
    const thresholdXMax = rect.right + 32;
    const thresholdYMin = rect.top - 32;
    const thresholdYMax = rect.bottom + 32;

    const isWithinThreshold =
      event.clientX >= thresholdXMin && event.clientX <= thresholdXMax && event.clientY >= thresholdYMin && event.clientY <= thresholdYMax;

    setShowBackButton(isWithinThreshold);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }} onMouseMove={handleMouseMove}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box component="main" sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 0, md: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit', mb: isHorizontal ? 2 : 'inherit' }} />

        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <motion.div layout transition={{ duration: 0.2, ease: 'easeIn' }}>
            <Stack flexGrow={0} mb={2} direction="row" spacing={1} alignItems="center">
              <Stack ref={backButtonRef} sx={{ width: showBackButton ? 44 : 0 }}>
                {showBackButton && (
                  <motion.div
                    key="back-button"
                    layout
                    initial={{ opacity: 0, rotateY: '-90deg', x: -20, scale: 0 }}
                    animate={{ opacity: 1, rotateY: '0deg', x: 0, scale: 1 }}
                    exit={{ opacity: 0, rotateY: '-90deg', x: -20, scale: 0 }}
                    transition={{ duration: 0.2, ease: 'easeIn' }}
                  >
                    <CustomTooltip title="Geri Git" placement="bottom" arrow>
                      <IconButton
                        // Attach the reference here
                        shape="rounded"
                        variant="contained"
                        color="secondary"
                        onClick={handleBackButtonClick}
                        size="large"
                      >
                        <ArrowLeft2 fontSize={'32'} />
                      </IconButton>
                    </CustomTooltip>
                  </motion.div>
                )}
              </Stack>

              <motion.div key="breadcrumbs" layout transition={{ duration: 0.2, ease: 'easeIn' }}>
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} icons />
              </motion.div>
            </Stack>
          </motion.div>
          <motion.div key="outlettt" layout transition={{ duration: 0.2, ease: 'easeIn' }}>
            <Outlet />
          </motion.div>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
