// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Bank, Ticket, MoneySend, Moneys, ArrowSwapHorizontal, Hierarchy3, ReceiptEdit } from 'iconsax-react';

// icons
const icons = {
  vaults: Bank,
  checks: Ticket,
  transactions: Moneys,
  currents: MoneySend,
  transfers: ArrowSwapHorizontal,
  coa: Hierarchy3,
  journals: ReceiptEdit
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const accounting = {
  id: 'accounting',
  title: <FormattedMessage id="accounting" />,
  type: 'group',
  disabled: true,

  children: [
    {
      id: 'vaults',
      title: <FormattedMessage id="vaults" />,
      type: 'item',
      url: '/vaults',
      icon: icons.vaults,
      chip: {
        label: 'Beta',
        color: 'primary',
        variant: 'outlined',
        size: 'small'
      }
    },
    {
      id: 'transactions',
      title: <FormattedMessage id="transactions" />,
      type: 'item',
      url: '/transactions',
      icon: icons.transactions,

      chip: {
        label: 'Beta',
        color: 'primary',
        variant: 'outlined',
        size: 'small'
      }
    },
    {
      id: 'transfers',
      title: <FormattedMessage id="transfers" />,
      type: 'item',
      url: '/transfers',
      icon: icons.transfers,
      disabled: true,
      chip: {
        label: 'Beta',
        color: 'primary',
        variant: 'outlined',
        size: 'small'
      }
    },
    {
      id: 'coa',
      title: <FormattedMessage id="chart-of-accounts" />,
      type: 'item',
      url: '/coa',
      icon: icons.coa,
      chip: {
        label: 'Beta',
        color: 'primary',
        variant: 'outlined',
        size: 'small'
      }
    },
    {
      id: 'journals',
      title: <FormattedMessage id="journals" />,
      type: 'item',
      url: '/journals',
      icon: icons.journals,
      chip: {
        label: 'Beta',
        color: 'primary',
        variant: 'outlined',
        size: 'small'
      }
    }
    /* {
      id: 'currents',
      title: <FormattedMessage id="currents" />,
      type: 'item',
      url: '/currents',
      icon: icons.currents,
      disabled: true
    }, */
  ]
};

export default accounting;
