import { useState, useEffect } from 'react';

const useSelectedCompany = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    const load = () => {
      const savedCompany = sessionStorage.getItem('selectedCompany');
      if (savedCompany) {
        setSelectedCompany(JSON.parse(savedCompany));
      } else {
        setSelectedCompany(null);
      }
    };

    load();

    window.addEventListener('storage', load);

    return () => {
      window.removeEventListener('storage', load);
    };
  }, []);

  return selectedCompany;
};

export default useSelectedCompany;
